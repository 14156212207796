import React from "react"
import autoBind from "react-autobind"
import { Button } from "react-bootstrap"
import classNames from "classnames"
import _ from "lodash"
import { Cartesian3, VerticalOrigin, HorizontalOrigin } from "cesium/Cesium"
import { BillboardCollection, Billboard } from "resium"
import ReactGA from "react-ga"
import PropTypes from 'prop-types'
import * as moment from "moment";

import appState from "../../CR-Components/CoreFiles/state.js"
import PurchaseModal from "../../CR-Components/PurchaseModal/PurchaseModal.jsx"
import NiceSelect from "../../CR-Components/UI-Utils/NiceSelect.jsx"
import CesiumGeocoder from "../../CR-Components/cesium/CesiumGeocoder.jsx"
import Loading from "../../CR-Components/Loading.jsx"
import ProcessingModal from "../ProcessingModal/ProcessingModal.jsx"
import AnalysisResult from "../AnalysisResult/AnalysisResult.jsx"
import LoginForm from "../../CR-Components/Account/LoginForm.jsx"
import {
    history,
    parseHashParams,
    pushLocationHashParams,
    replaceLocationHashParams,
    goToAnalysisId,
} from "../../CR-Components/CoreFiles/history"
import CesiumViewer from "../../CR-Components/cesium/CesiumViewer.jsx"
import {
    CR_doAnalysis,
    saveSearch,
    getAnalysisGroup,
    updateFieldDefaults,
} from "../../CR-Components/CoreFiles/analyses"
import ErrorModal from "../../CR-Components/ErrorModal/ErrorModal.jsx"
import Instructions from "../Instructions/Instructions.jsx"

import "./AssetForm.scss"
// import accountManager from "../../CR-Components/easy-cv-core/account";
import CopyLinkModal from "../../CR-Components/CopyLinkModal/CopyLinkModal.jsx"
import accountManager from '../../CR-Components/CoreFiles/account'

ReactGA.initialize("UA-148155584-1")

const defaultCesiumViewerHeight = 1000

class AssetForm extends React.Component {

    static propTypes = {
        account: PropTypes.object,
        analyses: PropTypes.object,
        analysisId: PropTypes.string,
        fieldDefaultValues: PropTypes.object,
    }

    constructor(props) {
        super(props)

        const { formValues } = parseHashParams({ formValues: {} })
        // This is used for retrieving saved analyses.
        const { analysisId } = props

        if (!formValues.whenType) formValues.whenType = "select"

        let cesiumFlyToDest = null
        if (formValues.latitude) {
            cesiumFlyToDest = {
                latitude: formValues.latitude,
                longitude: formValues.longitude,
                height: defaultCesiumViewerHeight,
            }
        }

        this.state = {
            expanded: true,
            selectingLocation: false,
            formValues,
            tempFormValues: formValues,
            loadingResults: false,
            archetypes: null,
            showModal: false,
            showPurchaseModal: false,
            acceptedPurchaseNotice: false,
            purchase: false,
            analysis: analysisId && props.analyses[analysisId],
            assetCategoryButton: "form-group-next",
            assetTypeButton: "form-group",
            assetLifeTimeButton: "form-group",
            locationButton: "form-group",
            locationTypeField: "form-group",
            cesiumFlyToDest,
            assetCustomisationShow: false,
            customisationValues: null,
            customisationElements: null,
            locationType: "address",
            assetLifeEnd: 2100, // This is ok, only used for default search
            checkboxChecked: false,
            displayProcessingModal: false,
            thanks: false,
            showInstructions: false,
            showCopyLinkModal: false,
            defaultParams: null,
            freeSiteCheck: false
        }
        autoBind(this)
    }

    async UNSAFE_componentWillMount() {
        const archetypeHierarchy = await jQuery.ajax({
            // eslint-disable-next-line no-undef
            url: `${crConstants.acrobaseHost}/v1/archetype_hierarchy`,
        })
        //Service	Family	Archetype

        const archetypeHierarchyFlattened = {}
        _.forOwn(archetypeHierarchy, (services) => {
            _.forOwn(services, (families, service) => {
                _.forOwn(families, (archetypes, family) => {
                    for (let archetype of archetypes) {
                        if (!archetypeHierarchyFlattened[service])
                            archetypeHierarchyFlattened[service] = {}
                        if (!archetypeHierarchyFlattened[service][family])
                            archetypeHierarchyFlattened[service][family] = []
                        archetypeHierarchyFlattened[service][family].push(archetype)
                    }
                })
            })
        })

        this.setState({
            archetypes: archetypeHierarchyFlattened,
        })

        this.updateFromHistoryLocation(history.location)

        this.historyUnlisten = history.listen(this.updateFromHistoryLocation)
    }

    componentWillUnmount() {
        this.historyUnlisten()
    }

    async componentDidMount() {
        const isWhitelisted = await accountManager.isOnWhitelist(this.props.account)
        this.setState({ freeSiteCheck: !isWhitelisted })


        // simplify this to use appState analysis
        if (this.props.analysisId && this.props.analyses[this.props.analysisId]) {
            const analysis = this.props.analyses[this.props.analysisId]
            updateFieldDefaults(analysis._id, this.props.analyses)
            pushLocationHashParams({
                formValues: analysis.inputs.asset.parameters,
                showModal: true,
            })
        } else {
            const analysis = this.getExistingAnalysisWithParameters(this.state.formValues)

            if (analysis) {
                goToAnalysisId(analysis._id)
            }
        }

        // Center over user location
        // TODO: fix this. Does not work with https
        // function isEmpty(obj) {
        //   for (var key in obj) {
        //     if (obj.hasOwnProperty(key))
        //       return false;
        //   }
        //   return true;
        // }

        // if (isEmpty(formValues)) {
        //   var userLocation = await accountManager.getCountryNameFromIP();

        //   var cesiumFlyToDest = {
        //     latitude: userLocation.lat,
        //     longitude: userLocation.lon,
        //     height: 27000000,
        //   };

        //   setTimeout(() =>
        //     this.setState({
        //       cesiumFlyToDest: cesiumFlyToDest
        //     }), 3000)
        // }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // simplify this to use appState
        if (
            nextProps.analysisId &&
            this.props.analysisId != nextProps.analysisId &&
            !!nextProps.analyses[nextProps.analysisId]
        ) {
            const analysis = nextProps.analyses[nextProps.analysisId]
            this.setState({ analysis })
            setTimeout(
                () =>
                    pushLocationHashParams({
                        formValues: analysis.inputs.asset.parameters,
                        showModal: true,
                    }),
                0
            )
        }
        // If a purchase analysis has been requested but the user wasn't logged and now is.
        else if (
            !this.state.analysis &&
            this.state.purchase &&
            !this.props.account &&
            nextProps.account
        ) {
            setTimeout(this.handlePurchase, 0)
        }
        // If the current analysis has been updated.
        else if (
            nextProps.analysisId &&
            nextProps.analyses[nextProps.analysisId] !== this.state.analysis
        ) {
            this.setState({ analysis: nextProps.analyses[nextProps.analysisId] })
        }
    }

    /**
     * Updates the values from the url or page history
     *
     * @param {string} location - location data ?
     */
    updateFromHistoryLocation(location) {
        const { formValues, showModal, analysisId, thanks } = parseHashParams(
            { formValues: {} },
            location
        )
        const oldFormValues = this.state.formValues

        if (!formValues.whenType) formValues.whenType = "select"

        // If showing results.
        if (showModal && !this.state.showModal) {
            // If analysis not specified in history params,
            // check for an existing analysis with same form values and load if so.
            const analysis = !analysisId && this.getExistingAnalysisWithParameters(formValues)
            if (analysis) {
                goToAnalysisId(analysis._id)
            } else if (!this.state.analysis && !this.state.purchase) {
                setTimeout(this.handleSubmit, 0)
            }
        }

        this.setState({
            formValues,
            tempFormValues: formValues,
            showModal,
            thanks,
        })

        if (!formValues.address) {
            this.updateAddressFromLatLong(formValues)
        }

        // when returning from paypal this.state.cesiumFlyToDest is null. Populate from formValues
        if (!this.state.cesiumFlyToDest && formValues.latitude) {
            this.setState({
                cesiumFlyToDest: {
                    latitude: formValues.latitude,
                    longitude: formValues.longitude,
                    height: defaultCesiumViewerHeight,
                },
            })
        }

        if (
            oldFormValues.latitude != formValues.latitude ||
            (oldFormValues.longitude != formValues.longitude &&
                formValues.latitude !== undefined &&
                formValues.longitude !== undefined)
        ) {
            this.setState((prevState) => {
                return {
                    cesiumFlyToDest: {
                        height: Math.min(prevState.cesiumFlyToDest.height, 10000),
                        latitude: formValues.latitude,
                        longitude: formValues.longitude,
                    },
                }
            })
        }
    }

    getCurrentAnalysis() {
        const params = {
            ...this.state.formValues,
        }
        return this.getExistingAnalysisWithParameters(params)
    }
    /**
     * returns a matching analysis from auth with matching parameters
     *
     * @param {object} params - analysis parameters to search for
     *
     * @returns {object} - analysis object
     */
    getExistingAnalysisWithParameters(params) {
        for (const analysis of Object.values(this.props.analyses)) {
            let match = true
            for (let field of ["archetype", "latitude", "longitude", "assetLifeEnd"]) {
                if (analysis.inputs.asset.properties[field] != params[field]) {
                    match = false
                    break
                }
            }
            if (match) {
                updateFieldDefaults(analysis._id, this.props.analyses)
                return analysis
            }
        }
    }

    /**
     * Sets the passed analysis to be the current analysis
     *
     * @param {object} analysis -  analysis object
     */
    setAnalysis(analysis) {
        if (analysis._id) {
            goToAnalysisId(analysis._id)
        } else {
            pushLocationHashParams({ analysisId: null })
            this.setState({ analysis })
        }
    }

    /**
     * Controls actions when the map is clicked
     *
     * @param {object} position - cartographic location passed by the click
     */
    handleCesiumClick(position) {
        const { locationType } = this.state.formValues
        // if (this.isRow2Visible() && locationType == 'select') {
        if (locationType == "select") {
            this.updateLocationFromCartographic(position)
            this.setState({ selectingLocation: false })
        }
    }
    /**
     * Updates the current view location from cartographic
     *
     * @param {object} cartographic - {latitude: X, longitude: X}
     */
    async updateLocationFromCartographic(cartographic) {
        const { formValues } = this.state

        if (!this.state.cesiumFlyToDest && formValues.latitude) {
            this.setState({
                cesiumFlyToDest: {
                    latitude: formValues.latitude,
                    longitude: formValues.longitude,
                    height: defaultCesiumViewerHeight,
                },
            })
        } else {
            this.setState((prevState) => {
                return {
                    cesiumFlyToDest: {
                        ...cartographic,
                        height: Math.min(
                            prevState.cesiumFlyToDest
                                ? prevState.cesiumFlyToDest.height
                                : defaultCesiumViewerHeight,
                            defaultCesiumViewerHeight
                        ),
                    },
                }
            })
        }

        pushLocationHashParams({
            formValues: {
                ...formValues,
                ...cartographic,
            },
        })

        if (formValues.locationType != "address") {
            await this.updateAddressFromLatLong(cartographic)
        }
    }

    /**
     * Sets the address if a lat lon is passed. Use for map clicks
     *
     * @param {object} param0 - { latitude, longitude }
     */
    async updateAddressFromLatLong({ latitude, longitude }) {
        if (typeof latitude == "number" && typeof longitude == "number") {
            // eslint-disable-next-line no-undef
            const url = `https://dev.virtualearth.net/REST/v1/Locations/${latitude},${longitude}?o=json&key=${crConstants.BingMapsKey}`
            const result = await jQuery.ajax({ url })
            const address = _.get(result, "resourceSets.0.resources.0.address.formattedAddress")
            address && pushLocationHashParams({ formValues: { ...this.state.formValues, address } })

            if (!address) {
                this.setState({
                    formValues: {
                        ...this.state.formValues,
                        address: `${latitude}, ${longitude}`
                    }
                })
            }
        }
    }

    handleReset() {
        this.setState({ analysis: null, formValues: {} })
    }

    isRow2Visible() {
        const { archetypeService, whenType, locationType } = this.state.formValues
        return !!(archetypeService && whenType && locationType)
    }

    isFormComplete() {
        const { latitude, longitude } = this.state.formValues
        return !!(latitude && longitude)
    }

    /**
     * updates values when the form is changed, changes the fields in form 2
     *
     * @param {object} changes - selected form values
     */
    handleFormChange(changes) {
        changes = changes || this.state.tempFormValues

        let formValues = {
            ...this.state.formValues,
            ...changes,
        }
        formValues = _.pickBy(formValues, (value) => value !== null && value !== undefined)
        if (changes.locationType == "select") {
            delete formValues.latitude
            delete formValues.longitude
        }
        pushLocationHashParams({ formValues })
    }

    /**
     * Handles temp form changes (not sure, find out...)
     *
     * @param {object} changes - temporary form changes
     */
    handleTempFormChange(changes) {
        this.setState({
            tempFormValues: {
                ...this.state.tempFormValues,
                ...changes,
            },
        })

        if (this.updateHistoryTimeoutHandle) clearTimeout(this.updateHistoryTimeoutHandle)
        this.updateHistoryTimeoutHandle = setTimeout(this.handleFormChange, 1000)
    }

    /**
     * closes current analysis and clears values from params
     */
    handleAnalysisResultClose() {
        this.setState({ analysis: null })
        window.document.title = "EasyXDI"
        var retainedValues = this.state.formValues

        // Keep archetype values
        var keep = ["whenType", "locationType", "latitude", "longitude", "address", "archetype", "archetypeService"]

        Object.keys(retainedValues).forEach(function (key) {
            if (keep.indexOf(key) < 0) {
                delete retainedValues[key]
            }
        })
        pushLocationHashParams({ showModal: false, analysisId: null, formValues: retainedValues })
    }

    /**
     * Opens saved analysis
     * TODO: rename
     *
     * @param {object} analysis - analysis to open
     */
    handleSaveAnalysis(analysis) {
        if (!this.state.analysis._id && analysis._id) {
            goToAnalysisId(analysis._id)
        }
    }

    handleChange() {
        this.disablePopOver()
        const { formValues } = this.state

        formValues.locationType = "select"
        this.setState({ selectingLocation: true, formValues })

        pushLocationHashParams({ formValues })
    }

    renderCesium() {
        const { formValues, cesiumFlyToDest } = this.state
        const { latitude, longitude } = formValues

        return (
            <CesiumViewer
                className="AssetForm-CesiumContainer"
                contextRef={(ref) => this.setState({ cesiumContext: ref })}
                flyTo={cesiumFlyToDest}
                onNewLocation={(pos) => this.setState({ cesiumFlyToDest: pos })}
                onClick={this.handleCesiumClick}>
                {latitude && longitude && (
                    <BillboardCollection>
                        <Billboard
                            show={longitude && latitude}
                            position={
                                longitude && latitude && Cartesian3.fromDegrees(longitude, latitude)
                            }
                            image="/static/images/pin.svg"
                            height="50"
                            width="30"
                            verticalOrigin={VerticalOrigin.BOTTOM}
                            horizontalOrigin={HorizontalOrigin.CENTER}
                        />
                    </BillboardCollection>
                )}
            </CesiumViewer>
        )
    }

    paymentCompleted() {
        this.setState({
            purchase: true,
            showPurchaseModal: false,
            acceptedPurchaseNotice: true,
            thanks: true,
        })
        replaceLocationHashParams({ thanks: true })
    }

    disablePopOver() {
        if (this.state.showInstructions === true) {
            this.setState({ showInstructions: false })
        }
    }

    async handleCopyLink() {
        const el = document.createElement("textarea")
        // eslint-disable-next-line no-undef
        el.value = `${crConstants.domain}/?analysisId=${this.state.analysis._id}`
        document.body.appendChild(el)
        el.select()
        document.execCommand("copy")
        document.body.removeChild(el)
        await this.setState({ showCopyLinkModal: true })
        setTimeout(() => {
            this.setState({ showCopyLinkModal: false })
        }, 2000)
    }

    handleCloseProcessingModal() {
        this.setState({
            analysis: null,
            displayProcessingModal: false,
            showModal: false,
        })

        pushLocationHashParams({ showModal: false })
    }

    render() {
        const {
            archetypes,
            formValues,
            expanded,
            selectingLocation,
            loadingResults,
            showModal,
            analysis,
            error,
            purchase,
            showPurchaseModal,
            cesiumContext,
            displayProcessingModal,
            showInstructions,
            showCopyLinkModal,
            defaultParams,
            freeSiteCheck,
        } = this.state
        const { account, fieldDefaultValues } = this.props

        if (!archetypes) return <Loading />

        const hiding = !expanded || selectingLocation

        const { archetypeService, archetype, address, latitude } = formValues

        const analysisGroup = (analysis && analysis.groupId && getAnalysisGroup(analysis)) || []

        return (
            <div className="AssetForm-wrap">
                {showInstructions && (
                    <Instructions
                        instructions={this.getInstructions()}
                        hide={this.disablePopOver}
                        completed={{
                            location: !!latitude,
                            archetypeService,
                            archetype,
                            search: showModal,
                        }}
                    />
                )}

                {this.renderCesium()}

                <div className={classNames("AssetForm", hiding && "collapsed")}>
                    {showModal && analysis && !displayProcessingModal ? (
                        <AnalysisResult
                            analysis={analysis}
                            fieldDefaultValues={fieldDefaultValues}
                            analysisGroup={analysisGroup}
                            handleClose={this.handleAnalysisResultClose}
                            onSaveAnalysis={this.handleSaveAnalysis}
                            onPurchase={this.handlePurchase}
                            onSwitchAnalysis={goToAnalysisId}
                            onNewAnalysis={this.setAnalysis}
                            account={account}
                            onCopyLink={this.handleCopyLink}
                            defaultParams={defaultParams}
                        />
                    ) : (
                        false
                    )}

                    {showCopyLinkModal && (
                        <CopyLinkModal
                            show={showCopyLinkModal}
                            onHide={() => this.setState({ showCopyLinkModal: false })}
                        />
                    )}

                    {showPurchaseModal && (
                        <PurchaseModal
                            onCancel={() => {
                                this.setState({ showPurchaseModal: false, purchase: false })
                                window.document.title = "EasyXDI"
                            }}
                            analysisAddress={analysis.properties.address}
                            analysisArchetype={analysis.properties.archetype}
                            paymentCompleted={this.paymentCompleted}
                            analysis={analysis}
                            materials={appState.materials}
                        />
                    )}

                    {hiding && (
                        <div className="back-to-menu">
                            <Button
                                className="search-buttons"
                                onClick={() =>
                                    this.setState({ expanded: true, selectingLocation: false })
                                }>
                                Back to Menu
                            </Button>
                            <p>Zoom and click a location</p>
                        </div>
                    )}

                    <div className={classNames("form", hiding ? "collapsed" : "expanded")}>
                        <div className="form-header">
                            <h1 className="form-header-top">
                                <strong>
                                    Welcome to EasyXDI
                                </strong>
                            </h1>
                            <p className="subtitle">
                                <strong>
                                    Ready to understand the climate risk to your asset?
                                </strong>
                            </p>
                        </div>
                        <div className="form-desc">
                            <div>
                                With EasyXDI you can search and analyse the extreme weather and climate risk on any infrastructure or property anywhere in the world from now to 2100 in 4 easy steps:
                            </div>
                            <ol>
                                <li>Type in a property or infrastructure address</li>
                                <li>Select an asset category from the drop-down menu</li>
                                <li>Select an asset type from the drop-down menu</li>
                                <li>Hit GO to run your search</li>
                            </ol>
                        </div>
                        <div className="form-section">
                            <div className="geocoder-box" ref={(ref) => (this.addressRef = ref)}>
                                <CesiumGeocoder
                                    className="mr-sm-2 search-form"
                                    cesiumContext={cesiumContext}
                                    initialQuery={address || ""}
                                    disabled={purchase || loadingResults}
                                    onSelect={({ address, cartographic }) => {
                                        this.state.formValues = {
                                            ...formValues,
                                            address,
                                            locationType: "address"
                                        }
                                        pushLocationHashParams({
                                            formValues,
                                        })
                                        this.updateLocationFromCartographic(cartographic)
                                        this.disablePopOver()
                                    }}
                                />

                                <a className="select-from-map" onClick={this.handleChange}>
                                    <i className="material-icons my_location"></i>
                                </a>
                            </div>

                            <div ref={(ref) => (this.archetypeServiceRef = ref)}>
                                <NiceSelect
                                    placeholder="asset category"
                                    options={Object.keys(archetypes).map((service) => {
                                        return { value: service, label: service }
                                    })}
                                    value={archetypeService}
                                    onChange={(val) =>
                                        this.handleFormChange(
                                            { archetypeService: val, archetype: null },
                                            this.disablePopOver()
                                        )
                                    }
                                    onSelect={() => this.disablePopOver()}
                                    isDisabled={purchase || loadingResults}
                                    isSearchable={false}
                                />
                            </div>

                            <div ref={(ref) => (this.archetypeRef = ref)}>
                                <NiceSelect
                                    placeholder="asset type"
                                    key={archetypeService}
                                    value={archetype}
                                    onChange={(val) =>
                                        this.handleFormChange(
                                            { archetype: val, assetLifeEnd: 2100 },
                                            this.disablePopOver()
                                        )
                                    }
                                    onSelect={() => this.disablePopOver()}
                                    isDisabled={!archetypeService || purchase || loadingResults}
                                    isSearchable={false}
                                    options={
                                        !archetypeService
                                            ? []
                                            : Object.keys(archetypes[archetypeService])
                                                .map(
                                                    (family) => {
                                                        return {
                                                            label: family,
                                                            options: archetypes[archetypeService][
                                                                family
                                                            ].map((archetype) => {
                                                                return {
                                                                    value: archetype.acronym,
                                                                    label: archetype.name,
                                                                }
                                                            }),
                                                        }
                                                    }
                                                )
                                    }
                                />
                            </div>

                            <Button
                                className="search-buttons"
                                id="start-search"
                                disabled={loadingResults || !this.isFormComplete()}
                                onClick={() => this.handleSubmitButton()}
                                ref={(ref) => (this.searchRef = ref)}>
                                GO
                            </Button>
                        </div>

                        {error && (
                            <ErrorModal
                                onClose={() => this.setState({ error: null })}
                                error={error}
                            />
                        )}

                        {!account && purchase && (
                            <div className="login-to-continue">
                                <h5>Please login or create an account to continue</h5>
                                <LoginForm
                                    showRegister={true}
                                    onCancel={() => this.setState({ purchase: false })}
                                />
                            </div>
                        )}

                        {displayProcessingModal && (
                            <ProcessingModal
                                analysis={analysis}
                                analysisAvailable={!!analysis}
                                account={account}
                                freeSiteCheck={freeSiteCheck}
                                onClose={this.handleCloseProcessingModal}
                                onDone={() => {
                                    this.setState({ displayProcessingModal: false, showModal: true })
                                    pushLocationHashParams({ showModal: true })
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }

    async logToBlackhole(body) {
        return await fetch(crConstants.blackhole, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dateCreated: moment().format('YYYY-MM-DD H:mm:ss'),
                ...body,
                origin: crConstants.domain
            })
        })
    }

    async handleSubmitButton() {
        // Auto fill townhouse on ground if nothing is selected.
        if (!this.state.formValues.archetype) {
            this.handleFormChange({ archetypeService: "Building" })
            this.setState({
                formValues: {
                    archetypeService: "Building",
                    archetype: "SO",
                    assetLifeEnd: 2100,
                },
            })
            this.handleFormChange({ archetype: "SO", assetLifeEnd: 2100 })
        }

        this.setState({ displayProcessingModal: true })
        setTimeout(() => this.handleSubmit(), 1000)

        try {
            var userName = ""
            if (this.props.account) {
                userName = this.props.account.username
            }

            const params = {
                ...this.state.formValues,
                ...(this.state.customisationValues || {}),
                username: userName,
            }
            // save search parameters to auth provider
            saveSearch(params)
            await this.logToBlackhole({ event: 'search', params })
        } catch {
            console.log("Log search failed")
        }
    }

    async handleSubmit() {
        this.setState({ loadingResults: true, analysis: null })

        try {
            const params = {
                ...this.state.formValues,
                ...(this.state.customisationValues || {}),
            }

            // clears defaults from previous analysis
            appState.fieldDefaultValues = {}

            let analysis = await CR_doAnalysis({
                parameters: params,
                elements: this.state.customisationElements,
            })

            // In case a back navigation occurred after pressing the go button.
            // if (!this.state.showModal) return;
            this.setAnalysis(analysis)
            this.setState({
                defaultParams: {
                    params: { ...appState.fieldDefaultValues },
                },
            })
        } catch (ex) {
            // eslint-disable-next-line no-undef
            if (!crConstants.disableGlobalErrorCatching) {
                this.setState({
                    error: ex,
                    displayProcessingModal: false,
                })
            } else {
                console.log(ex.stack)
                throw ex
            }
        }

        this.setState({ loadingResults: false })
    }

    async handlePurchase() {
        if (!this.props.account) {
            this.setState({
                purchase: true,
                showPurchaseModal: false,
                acceptedPurchaseNotice: true,
            })
        } else {
            this.setState({
                purchase: true,
                showPurchaseModal: true,
                acceptedPurchaseNotice: true,
            })
        }
    }

    getInstructions() {
        if (!this.instructions) {
            this.instructions = [
                {
                    key: "location",
                    ref: () => this.addressRef,
                    title: "Step 1",
                    content: (
                        <React.Fragment>
                            Enter your address, or click on the{" "}
                            <i className="material-icons my_location"></i> button to select a
                            location on the globe.
                        </React.Fragment>
                    ),
                },
                {
                    key: "archetypeService",
                    ref: () => this.archetypeServiceRef,
                    title: "Step 2",
                    content: <React.Fragment>Select what kind of asset you have.</React.Fragment>,
                },
                {
                    key: "archetype",
                    ref: () => this.archetypeRef,
                    title: "Step 3",
                    content: (
                        <React.Fragment>Select the specific kind of asset you have.</React.Fragment>
                    ),
                },
                {
                    key: "search",
                    ref: () => this.searchRef,
                    title: "Step 4",
                    content: (
                        <React.Fragment>
                            Click SEARCH to see the climate risk to your asset!
                        </React.Fragment>
                    ),
                },
            ]
        }
        return this.instructions
    }
}

export { AssetForm }
